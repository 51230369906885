import { OptionType } from "../types/instrument";
import { round } from "./price-with-tolerance";

export const calculateEstimatedTurboPriceLevel = (
  optionType: OptionType,
  inputUnderlyingLevel: number | null,
  knockoutLevel: number | undefined,
  multiplier: number,
  currentBidOrOffer: number | null,
  scaledUnderlyingLevel: number | null,
  scalingFactor: number,
  decimalPlacesFactor: number,
) => {
  if (
    inputUnderlyingLevel === null ||
    knockoutLevel === undefined ||
    multiplier === undefined ||
    scaledUnderlyingLevel === null ||
    currentBidOrOffer === null
  ) {
    return null;
  }

  const unscaledUnderlyingLevel = scaledUnderlyingLevel / scalingFactor;

  const isGoingLong = optionType === OptionType.CALL;
  const levelIsKOd = isGoingLong
    ? inputUnderlyingLevel <= knockoutLevel
    : inputUnderlyingLevel >= knockoutLevel;
  if (levelIsKOd) {
    return 0;
  }

  const premium = getPremium(
    isGoingLong,
    currentBidOrOffer,
    knockoutLevel,
    unscaledUnderlyingLevel,
    multiplier,
  );

  const estimatedTurboPrice = getEstimatedTurboPrice(
    isGoingLong,
    inputUnderlyingLevel,
    knockoutLevel,
    multiplier,
    premium,
  );

  if (estimatedTurboPrice <= 0) {
    return 0;
  }

  return round(estimatedTurboPrice, decimalPlacesFactor);
};

export const getPremium = (
  isGoingLong: boolean,
  currentBidOrOffer: number,
  knockoutLevel: number,
  unscaledUnderlyingLevel: number,
  multiplier: number,
) => {
  if (isGoingLong) {
    return (
      currentBidOrOffer - (unscaledUnderlyingLevel - knockoutLevel) * multiplier
    );
  }
  return (
    currentBidOrOffer - (knockoutLevel - unscaledUnderlyingLevel) * multiplier
  );
};

const getEstimatedTurboPrice = (
  isGoingLong: boolean,
  inputUnderlyingLevel: number,
  knockoutLevel: number,
  multiplier: number,
  premium: number,
) => {
  if (isGoingLong) {
    return (inputUnderlyingLevel - knockoutLevel) * multiplier + premium;
  }
  return (knockoutLevel - inputUnderlyingLevel) * multiplier + premium;
};

export const calculateEstimatedUnderlyingLevel = (
  optionType: OptionType,
  inputTurboPrice: number | undefined,
  knockoutLevel: number | undefined,
  multiplier: number,
  currentBidOrOffer: number | null,
  scaledUnderlyingLevel: number | null,
  scalingFactor: number,
  decimalPlacesFactor: number,
) => {
  if (
    inputTurboPrice === undefined ||
    inputTurboPrice === 0 ||
    knockoutLevel === undefined ||
    multiplier === undefined ||
    multiplier === 0 ||
    scaledUnderlyingLevel === null ||
    currentBidOrOffer === null ||
    scalingFactor === 0
  ) {
    return null;
  }

  const unscaledUnderlyingLevel = scaledUnderlyingLevel / scalingFactor;
  const isGoingLong = optionType === OptionType.CALL;
  const premium = getPremium(
    isGoingLong,
    currentBidOrOffer,
    knockoutLevel,
    unscaledUnderlyingLevel,
    multiplier,
  );
  const estimatedUnderlyingLevel = getEstimatedUnderlyingLevel(
    isGoingLong,
    inputTurboPrice,
    knockoutLevel,
    multiplier,
    premium,
  );
  return Number(estimatedUnderlyingLevel.toFixed(decimalPlacesFactor));
};

const getEstimatedUnderlyingLevel = (
  isGoingLong: boolean,
  turboPriceLevel: number,
  knockoutLevel: number,
  multiplier: number,
  premium: number,
) => {
  if (isGoingLong) {
    return (turboPriceLevel - premium) / multiplier + knockoutLevel;
  }
  return knockoutLevel - (turboPriceLevel - premium) / multiplier;
};
