import { type Instrument } from "../types/instrument";
import { InstrumentType } from "../types/instrument-type";

export const resolveInstrumentTypeByInstrument = (
  instrument: Instrument,
): InstrumentType => {
  return resolveInstrumentTypeByString(instrument.instrumentType);
};

// Leaving this function in order to add sharedealing as part of Upvest project
export const resolveInstrumentTypeByString = (
  instrumentType: string,
): InstrumentType => {
  return InstrumentType.TURBO;
};
