import { type Ticket } from "../../types/ticket";
import { type DispatchWithoutAction } from "react";
import {
  BUY_TOLERANCE,
  createTicketModel,
  SELL_TOLERANCE,
} from "../../services/ticket-model-factory";
import { type Instrument } from "../../types/instrument";
import {
  OrderType,
  type TimeInForce,
} from "../../types/ig-trading-order-ticket";
import {
  type ValidationError,
  type ValidationErrors,
} from "../../types/ticket-validation";
import { Direction } from "../../types/directions";

export default function ticketReducer(
  ticket: Ticket | null,
  action: TicketAction,
): any | DispatchWithoutAction {
  if (action.type === TicketActions.INITIAL_SETUP_OF_TICKET) {
    return createTicketModel(
      action.payload.direction,
      action.payload.instrument,
      action.payload.size,
      action.payload.dealReference,
      action.payload.timeInForce,
      action.payload.orderType,
    );
  }

  if (ticket == null) return null;

  switch (action.type) {
    case TicketActions.REMOVE_TICKET_VALIDATION_ERROR: {
      const filteredValidationErrors = ticket.validationErrors.filter(
        (validationError: ValidationError) =>
          validationError.key !== action.payload.validationErrorKeyToRemove,
      );
      return {
        ...ticket,
        validationErrors: filteredValidationErrors,
      };
    }
    case TicketActions.UPDATE_TICKET_VALIDATION_ERROR: {
      return { ...ticket, validationErrors: action.payload.validationErrors };
    }
    case TicketActions.UPDATE_TICKET_DIRECTION: {
      if (
        ticket.orderType === OrderType.STOP_MARKET &&
        action.payload.direction === Direction.BUY
      )
        return {
          ...ticket,
          direction: action.payload.direction,
          stopPrice: undefined,
          orderType: OrderType.LIMIT,
        };
      return { ...ticket, direction: action.payload.direction };
    }
    case TicketActions.UPDATE_TICKET_SIZE: {
      return { ...ticket, size: action.payload.size };
    }
    case TicketActions.UPDATE_DEAL_REFERENCE: {
      return { ...ticket, dealReference: action.payload.dealReference };
    }
    case TicketActions.UPDATE_ORDER_TYPE: {
      if (
        ticket.orderType === OrderType.LIMIT &&
        action.payload.orderType !== OrderType.LIMIT
      ) {
        return {
          ...ticket,
          triggerLevel: undefined,
          orderType: action.payload.orderType,
        };
      } else if (
        ticket.orderType === OrderType.STOP_MARKET &&
        action.payload.orderType !== OrderType.STOP_MARKET
      ) {
        return {
          ...ticket,
          stopPrice: undefined,
          orderType: action.payload.orderType,
        };
      }
      return { ...ticket, orderType: action.payload.orderType };
    }
    case TicketActions.UPDATE_TIME_IN_FORCE: {
      return { ...ticket, timeInForce: action.payload.timeInForce };
    }
    case TicketActions.UPDATE_TRIGGER_LEVEL: {
      return { ...ticket, triggerLevel: action.payload.triggerLevel };
    }
    case TicketActions.UPDATE_STOP_PRICE: {
      return { ...ticket, stopPrice: action.payload.stopPrice };
    }
    case TicketActions.UPDATE_TRIGGER_PRICE: {
      return ticket.orderType === OrderType.STOP_MARKET
        ? { ...ticket, stopPrice: action.payload.triggerPrice }
        : { ...ticket, triggerLevel: action.payload.triggerPrice };
    }
    case TicketActions.UPDATE_PRICE_TOLERANCE: {
      return {
        ...ticket,
        priceTolerance:
          action.payload.direction === Direction.BUY
            ? BUY_TOLERANCE
            : SELL_TOLERANCE,
      };
    }
    default:
      // @ts-expect-error Cancelling type safety here to log for errors in case of undefined actions (which should not happen)
      throw new Error(`No function setup for ${action.type as string}`);
  }
}

export type TicketAction =
  | RemoveTicketValidationError
  | UpdateTimeInForce
  | UpdateOrderType
  | UpdateStopPrice
  | UpdateTriggerLevel
  | UpdateTriggerPrice
  | UpdateDealReference
  | UpdateTicketSize
  | InitialSetupOfTicket
  | UpdateTicketDirection
  | UpdateTicketValidationError
  | UpdatePriceTolerance;

interface UpdateTicketValidationError {
  type: TicketActions.UPDATE_TICKET_VALIDATION_ERROR;
  payload: {
    validationErrors: ValidationError[];
  };
}

interface RemoveTicketValidationError {
  type: TicketActions.REMOVE_TICKET_VALIDATION_ERROR;
  payload: {
    validationErrorKeyToRemove: ValidationErrors;
  };
}

interface UpdateTicketDirection {
  type: TicketActions.UPDATE_TICKET_DIRECTION;
  payload: {
    direction: Direction;
  };
}

interface InitialSetupOfTicket {
  type: TicketActions.INITIAL_SETUP_OF_TICKET;
  payload: {
    direction: Direction;
    instrument: Instrument;
    size: number | undefined;
    dealReference: string;
    timeInForce: TimeInForce;
    orderType: OrderType;
  };
}

interface UpdateTicketSize {
  type: TicketActions.UPDATE_TICKET_SIZE;
  payload: {
    size: number | undefined;
  };
}

interface UpdateDealReference {
  type: TicketActions.UPDATE_DEAL_REFERENCE;
  payload: {
    dealReference: string;
  };
}

interface UpdateOrderType {
  type: TicketActions.UPDATE_ORDER_TYPE;
  payload: {
    orderType: OrderType;
  };
}

interface UpdateStopPrice {
  type: TicketActions.UPDATE_STOP_PRICE;
  payload: {
    stopPrice: number | undefined;
  };
}

interface UpdateTimeInForce {
  type: TicketActions.UPDATE_TIME_IN_FORCE;
  payload: {
    timeInForce: TimeInForce;
  };
}

interface UpdateTriggerLevel {
  type: TicketActions.UPDATE_TRIGGER_LEVEL;
  payload: {
    triggerLevel: number | undefined;
  };
}

interface UpdateTriggerPrice {
  type: TicketActions.UPDATE_TRIGGER_PRICE;
  payload: {
    triggerPrice: number | undefined;
  };
}

interface UpdatePriceTolerance {
  type: TicketActions.UPDATE_PRICE_TOLERANCE;
  payload: {
    direction: Direction;
  };
}

export enum TicketActions {
  INITIAL_SETUP_OF_TICKET = "initial-setup-of-ticket",
  REMOVE_TICKET_VALIDATION_ERROR = "remove-ticket-validation-error",
  UPDATE_DEAL_REFERENCE = "update-deal-reference",
  UPDATE_ORDER_TYPE = "update-order-type",
  UPDATE_STOP_PRICE = "update-stop-price",
  UPDATE_TICKET_DIRECTION = "update-ticket-direction",
  UPDATE_TICKET_SIZE = "update-ticket-size",
  UPDATE_TICKET_VALIDATION_ERROR = "update-ticket-validation-error",
  UPDATE_TIME_IN_FORCE = "update-time-in-force",
  UPDATE_TRIGGER_LEVEL = "update-trigger-level",
  UPDATE_TRIGGER_PRICE = "update-trigger-price",
  UPDATE_PRICE_TOLERANCE = "update-price-tolerance",
}
