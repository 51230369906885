import { useTranslation } from "react-i18next";
import { useETPTheme } from "../../hooks/use-etp-theme";
import { useSubscription } from "../../hooks/use-subscription";
import { OrderType } from "../../types/ig-trading-order-ticket";
import React, { type Dispatch, useCallback, useEffect, useState } from "react";
import { Direction } from "../../types/directions";
import {
  type TicketAction,
  TicketActions,
} from "../../hooks/reducers/ticket-reducer";
import { StopLimitRowStyles } from "./StopLimitRowStyles";
import Input from "../generics/input/Input";
import type { Ticket } from "../../types/ticket";
import { type ETPtheme } from "../../theme/etp-theme";
import { ValidationErrors } from "../../types/ticket-validation";
import TetheredWarningMessage from "../TetheredWarningMessage";
import RadioButton from "../generics/radioButton/RadioButton";
import {
  calculateEstimatedTurboPriceLevel,
  calculateEstimatedUnderlyingLevel,
} from "../../utils/turbo-calculator";
import TurboCalculator from "./TurboCalculator";
import { resolveInstrumentTypeByString } from "../../utils/resolve-instrument-type";
import { InstrumentType } from "../../types/instrument-type";
import { OptionType } from "../../types/instrument";

interface StopLimitRowProps {
  inputType: "limit" | "stop";
  dispatch: Dispatch<TicketAction>;
  ticket: Ticket;
  isInactive: boolean;
  isQuickDealDisplayed: boolean;
  setUnderlyingInputValue: (underlyingInputValue: number | null) => void;
  underlyingInputValue: number | null;
  underlyingScalingFactor: number;
  underlyingDecimalPlacesFactor: number;
}

const stopInputValidationErrorKeys = [
  ValidationErrors.STOP_INPUT_WARNING_ZERO,
  ValidationErrors.STOP_INPUT_LEVEL_ABOVE_BID,
];

const limitInputValidationErrorKeys = [
  ValidationErrors.LIMIT_INPUT_WARNING_ZERO,
];

const StopLimitRow = ({
  inputType,
  dispatch,
  ticket,
  isInactive,
  isQuickDealDisplayed,
  setUnderlyingInputValue,
  underlyingInputValue,
  underlyingScalingFactor,
  underlyingDecimalPlacesFactor,
}: StopLimitRowProps) => {
  const { t } = useTranslation();
  const ETPtheme: ETPtheme = useETPTheme();
  const { underlyingPrice, bid, offer } = useSubscription();

  const [calculatorIsFocused, setCalculatorIsFocused] =
    useState<boolean>(false);

  const orderType =
    inputType === "limit" ? OrderType.LIMIT : OrderType.STOP_MARKET;
  const inputValue =
    inputType === "limit" ? ticket.triggerLevel : ticket.stopPrice;
  const errorKeys =
    inputType === "limit"
      ? limitInputValidationErrorKeys
      : stopInputValidationErrorKeys;
  const isDisabled = inputType === "stop" && ticket.direction === Direction.BUY;
  const instrumentType = resolveInstrumentTypeByString(
    ticket.instrument.instrumentType,
  );

  const setFocus = useCallback(() => {
    document.getElementById(`${inputType}-row__input`)?.focus();
  }, [inputType]);

  useEffect(() => {
    if (!isInactive) {
      setFocus();
    }
  }, [isInactive, setFocus]);

  useEffect(() => {
    if (calculatorIsFocused || isInactive) return;

    const newUnderlyingInputValue = calculateEstimatedUnderlyingLevel(
      ticket.instrument.optionType,
      inputValue,
      ticket.instrument.strikeLevel,
      ticket.instrument.multiplier,
      ticket.direction === Direction.BUY ? offer : bid,
      underlyingPrice,
      underlyingScalingFactor,
      underlyingDecimalPlacesFactor,
    );

    if (newUnderlyingInputValue === null) {
      return;
    }

    if (newUnderlyingInputValue < 0) {
      setUnderlyingInputValue(0);
      return;
    }

    // At this stage, we are guaranteeing that instrument is turbo and has a strike level
    const optionType = ticket.instrument.optionType;
    const strikeLevel = ticket.instrument.strikeLevel;
    if (
      (optionType === OptionType.CALL &&
        newUnderlyingInputValue <= strikeLevel) ||
      (optionType === OptionType.PUT && newUnderlyingInputValue >= strikeLevel)
    ) {
      setUnderlyingInputValue(0);
      return;
    }

    setUnderlyingInputValue(newUnderlyingInputValue);
    // adding further dependencies results in unnecessary rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const handleRowSelected = () => {
    if (orderType !== ticket.orderType) {
      dispatch({
        type: TicketActions.UPDATE_ORDER_TYPE,
        payload: {
          orderType,
        },
      });
      dispatch({
        type: TicketActions.UPDATE_TRIGGER_LEVEL,
        payload: {
          triggerLevel: undefined,
        },
      });
      dispatch({
        type: TicketActions.UPDATE_STOP_PRICE,
        payload: {
          stopPrice: undefined,
        },
      });
    }
  };

  const updateTicketWithInputPrice = (inputPrice: number | null) => {
    dispatch({
      type: TicketActions.UPDATE_TRIGGER_PRICE,
      payload: {
        triggerPrice: inputPrice ?? undefined,
      },
    });
  };

  const getInitialValue = () => {
    if (
      (ticket.direction === Direction.BUY && offer === null) ||
      (ticket.direction === Direction.SELL && bid === null)
    ) {
      return 0;
    }
    return ticket.direction === Direction.BUY ? offer : bid;
  };

  const getValue = () => {
    if (orderType === ticket.orderType && inputValue !== undefined) {
      return inputValue.toString();
    }
    return "";
  };

  const setInputValue = (inputPrice: number | null) => {
    updateTicketWithInputPrice(inputPrice);
    if (inputPrice === null) {
      setUnderlyingInputValue(null);
    }
  };

  const stopLimitInputValidationError = ticket.validationErrors.find(
    (ticketValidationError) => errorKeys.includes(ticketValidationError.key),
  );

  const handleValidationErrors = () => {
    if (stopLimitInputValidationError !== undefined) {
      dispatch({
        type: TicketActions.REMOVE_TICKET_VALIDATION_ERROR,
        payload: {
          validationErrorKeyToRemove: stopLimitInputValidationError.key,
        },
      });
    }
  };

  return (
    <StopLimitRowStyles
      $ETPtheme={ETPtheme}
      data-testid={`${inputType}-input-container`}
      onClick={() => {
        handleValidationErrors();
        if (!isDisabled) {
          handleRowSelected();
        }
      }}
      $isInactive={isInactive}
      $isDisabled={isDisabled}
    >
      <div className="stop-limit-row__radio__container">
        <RadioButton
          name={orderType}
          radioButtonsName="stop-limit-selection"
          handleClick={setFocus}
          isChecked={ticket.orderType === orderType}
          isDisabled={isDisabled}
          radioLabelText={t(`order-tab.${inputType}`)}
        />
      </div>
      <TetheredWarningMessage
        testId={`deal-${inputType}-input`}
        warningMessage={
          stopLimitInputValidationError !== undefined
            ? t(stopLimitInputValidationError.key)
            : null
        }
        showWarningMessage={isQuickDealDisplayed}
      >
        <Input
          container={{
            id: "stop-limit-row__container",
          }}
          numericInput={{
            id: `${inputType}-row__input`,
            "data-testid": `${inputType}-input`,
            label: `${inputType}-input`,
            hasError: stopLimitInputValidationError !== undefined,
            handleValidationErrors,
            initialValue: getInitialValue() ?? 0,
            value: getValue(),
            setValue: setInputValue,
            max: 9_999_999,
            min: 0,
            step: 1 / Math.pow(10, ticket.instrument.decimalPlacesFactor),
            state: isDisabled ? "disabled" : isInactive ? "inactive" : "active",
          }}
        />
      </TetheredWarningMessage>
      {!isInactive && instrumentType === InstrumentType.TURBO ? (
        <TurboCalculator
          onChange={(underlyingValue) => {
            setUnderlyingInputValue(underlyingValue);
            updateTicketWithInputPrice(
              calculateEstimatedTurboPriceLevel(
                ticket.instrument.optionType,
                underlyingValue,
                ticket.instrument.strikeLevel,
                ticket.instrument.multiplier,
                ticket.direction === Direction.BUY ? offer : bid,
                underlyingPrice,
                underlyingScalingFactor,
                ticket.instrument.decimalPlacesFactor,
              ),
            );
          }}
          onFocus={() => {
            setCalculatorIsFocused(true);
          }}
          onBlur={() => {
            setCalculatorIsFocused(false);
          }}
          underlyingInputValue={underlyingInputValue}
        />
      ) : (
        <span />
      )}
    </StopLimitRowStyles>
  );
};

export default StopLimitRow;
