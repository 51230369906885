import { type Ticket } from "../types/ticket";
import { type Instrument } from "../types/instrument";
import { Direction } from "../types/directions";
import {
  type OrderType,
  type TimeInForce,
} from "../types/ig-trading-order-ticket";

export const BUY_TOLERANCE: number = 0.15;
export const SELL_TOLERANCE: number = 0.5;

export const createTicketModel = (
  direction: Direction,
  instrument: Instrument,
  size: number = 500,
  dealReference: string,
  timeInForce: TimeInForce,
  orderType: OrderType,
): Ticket => {
  return {
    direction,
    size,
    instrument,
    validationErrors: [],
    priceTolerance:
      direction === Direction.BUY ? BUY_TOLERANCE : SELL_TOLERANCE,
    dealReference,
    timeInForce,
    orderType,
  };
};
