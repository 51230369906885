import { useTranslation } from "react-i18next";
import { useSubscription } from "../../hooks/use-subscription";
import MarketDepthRow from "./MarketDepthRow";
import { type MarketDepths } from "../../types/pricing-data";
import MarketDepthDirectionButton from "./MarketDepthDirectionButton";
import { Direction } from "../../types/directions";
import { useETPTheme } from "../../hooks/use-etp-theme";
import { MarketDepthContainerStyles } from "./MarketDepthContainerStyles";
import { OrderType } from "../../types/ig-trading-order-ticket";
import { type Dispatch } from "react";
import { type TicketAction } from "../../hooks/reducers/ticket-reducer";

interface MarketDepthContainerProps {
  isMarketDepthExpanded: boolean;
  setIsMarketDepthExpanded: (value: boolean) => void;
  decimalPlacesFactor: number;
  selectedDirection: Direction;
  onDirectionButtonClick: (direction: Direction) => void;
  isSellDisabled: boolean;
  isBuyDisabled: boolean;
  orderType: OrderType;
  dispatch: Dispatch<TicketAction>;
}

const MarketDepthContainer = ({
  isMarketDepthExpanded,
  setIsMarketDepthExpanded,
  decimalPlacesFactor,
  selectedDirection,
  onDirectionButtonClick,
  isSellDisabled,
  isBuyDisabled,
  orderType,
  dispatch,
}: MarketDepthContainerProps) => {
  const { t } = useTranslation();
  const { bidDepth, offerDepth } = useSubscription();

  const etpTheme = useETPTheme();

  if (!isMarketDepthExpanded) {
    return (
      <button
        type="button"
        className="etp-quick-deal_external-link etp-quick-deal_external-link--light etp-quick-deal_external-link--centered"
        onClick={() => {
          setIsMarketDepthExpanded(true);
        }}
      >
        {t("market-depth.view-button")}
      </button>
    );
  }

  return (
    <MarketDepthContainerStyles $ETPtheme={etpTheme} data-testid="market-depth">
      <MarketDepthDirectionButton
        direction={Direction.SELL}
        isSelected={selectedDirection === Direction.SELL}
        onButtonClick={onDirectionButtonClick}
        isDisabled={isSellDisabled}
      />
      <MarketDepthDirectionButton
        direction={Direction.BUY}
        isSelected={selectedDirection === Direction.BUY}
        onButtonClick={onDirectionButtonClick}
        isDisabled={isBuyDisabled}
      />
      {/* use flex row-reverse in sell */}
      <ul data-testid="market-depth-sell-column">
        {mapPricesToComponents(
          bidDepth,
          decimalPlacesFactor,
          Direction.SELL,
          orderType,
          dispatch,
        )}
      </ul>
      <ul data-testid="market-depth-buy-column">
        {mapPricesToComponents(
          offerDepth,
          decimalPlacesFactor,
          Direction.BUY,
          orderType,
          dispatch,
        )}
      </ul>
      <button
        type="button"
        className="etp-quick-deal_external-link etp-quick-deal_external-link--light etp-quick-deal_external-link--centered"
        onClick={() => {
          setIsMarketDepthExpanded(false);
        }}
      >
        {t("market-depth.hide-button")}
      </button>
    </MarketDepthContainerStyles>
  );
};

const mapPricesToComponents = (
  marketDepths: MarketDepths | undefined,
  decimalPlacesFactor: number,
  direction: Direction,
  orderType: OrderType,
  dispatch: Dispatch<TicketAction>,
) => {
  return marketDepths !== undefined
    ? marketDepths.map((rowData, index) => {
        return (
          <MarketDepthRow
            key={`market-depth-${direction}-row-${index}`}
            price={rowData.price}
            volume={rowData.size}
            decimalPlacesFactor={decimalPlacesFactor}
            direction={direction}
            disabled={orderType === OrderType.MARKET || rowData.price === ""}
            dispatch={dispatch}
          />
        );
      })
    : Array.from({ length: 5 }).map((_, index) => {
        return (
          <MarketDepthRow
            key={`market-depth-${direction}-row-${index}`}
            price={undefined}
            volume={undefined}
            decimalPlacesFactor={decimalPlacesFactor}
            direction={direction}
            disabled={true}
            dispatch={dispatch}
          />
        );
      });
};

export default MarketDepthContainer;
