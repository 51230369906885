import { type MarketStatus } from "./pricing-data";

export enum OptionType {
  PUT = "PUT",
  CALL = "CALL",
}

export interface Currency {
  name: string;
  baseExchangeRate: number;
}

export interface DealingRules {
  currencyOffset: number;
}

export interface Instrument extends InstrumentSharedTypes {
  isin: string;
  optionType: OptionType;
  strikeLevel: number;
  multiplier: number;
}

export interface InstrumentUnderlying extends InstrumentSharedTypes {}

interface InstrumentSharedTypes {
  dealingRules: DealingRules;
  decimalPlacesFactor: number;
  displayBid: string;
  displayOffer: string;
  epic: string;
  expiryDetails: { expiryDate: undefined };
  igInstrumentId: string;
  instrumentName: string;
  instrumentType: string;
  ncrMarketStatus: MarketStatus;
  referenceEpic: string;
  scalingFactor: number;
  share: boolean;
  ticketDefaultCurrency: Currency;
}
