import { type Adapter } from "../../types/adapter";
import { type Ticket } from "../../types/ticket";
import {
  type Transaction,
  type IgTradingOrderTicket,
  type PartialTicket,
} from "../../types/ig-trading-order-ticket";
import {
  MTFService,
  Settings,
  SettingsProxy,
  TurboOrderTicket,
} from "ig-trading";
import {
  TECHNICAL_UNSUCCESSFUL_COMPLETED_STATES,
  type TransactionStatus,
} from "../../types/transaction-status";
import { resolveInstrumentTypeByInstrument } from "../../utils/resolve-instrument-type";
import { type IgTradingDealReferenceGenerator } from "./types";
import { type Prices } from "../../types/prices";
import { type Instrument } from "../../types/instrument";

export default class IgTradingAdapter implements Adapter {
  private readonly mtfService: any;
  private readonly igTradingSession: any;
  private readonly settings: any;
  private readonly dealReferenceGenerator: IgTradingDealReferenceGenerator;
  private readonly confirmationStreamingClient: any;

  constructor(
    igTradingSession: any,
    confirmationStreamingClient: any,
    dealReferenceGenerator: IgTradingDealReferenceGenerator,
  ) {
    this.igTradingSession = igTradingSession;
    this.dealReferenceGenerator = dealReferenceGenerator;
    this.confirmationStreamingClient = confirmationStreamingClient;
    this.mtfService = new MTFService(
      igTradingSession,
      this.confirmationStreamingClient,
      this.dealReferenceGenerator,
    );
    this.settings = new SettingsProxy({}, new Settings({}));
  }

  placeDeal = (
    quickDealTicket: Ticket,
    transactionStatusHandler: (transactionStatus: TransactionStatus) => void,
    prices: Prices,
  ) => {
    // TODO Properly construct heartbeat and referencePosition
    const heartbeat = null;
    const referencePosition = null;
    const IgTradingTicketType = this.getIgTradingTicket(quickDealTicket);

    const newInstrument = this.getInstrumentWithPrices(
      quickDealTicket.instrument,
      prices,
    );

    const igTradingOrderTicket: IgTradingOrderTicket = new IgTradingTicketType(
      this.mtfService,
      newInstrument,
      heartbeat,
      this.settings,
      this.dealReferenceGenerator,
      this.igTradingSession,
      referencePosition,
    );

    const ticketParams: PartialTicket = {
      dealReference: quickDealTicket.dealReference,
      direction: quickDealTicket.direction,
      orderType: quickDealTicket.orderType,
      size: quickDealTicket.size,
      timeInForce: quickDealTicket.timeInForce,
      tolerance: quickDealTicket.priceTolerance,
    };

    if (quickDealTicket.triggerLevel !== undefined) {
      ticketParams.triggerLevel = quickDealTicket.triggerLevel;
    }

    if (quickDealTicket.stopPrice !== undefined) {
      ticketParams.stopPrice = quickDealTicket.stopPrice;
    }

    igTradingOrderTicket.setProperties(ticketParams);

    const igTradingTransaction: Transaction = igTradingOrderTicket.submit();
    igTradingTransaction.on(
      "change",
      function () {
        // @ts-expect-error "this" refers to MTFCreateOrderTransaction
        const originalState = this.state;
        // @ts-expect-error "this" refers to MTFCreateOrderTransaction
        const originalDetails = this.details;
        const effectiveDetails =
          TECHNICAL_UNSUCCESSFUL_COMPLETED_STATES.includes(originalState)
            ? {
                content: {
                  reportType: originalState,
                },
              }
            : originalDetails;
        transactionStatusHandler({
          state: originalState,
          details: effectiveDetails,
        });
      },
      igTradingTransaction,
    );
    igTradingTransaction.start();
  };

  private getInstrumentWithPrices(instrument: Instrument, prices: Prices) {
    const newInstrument = Object.create(
      instrument,
      Object.getOwnPropertyDescriptors(instrument),
    );
    newInstrument.displayOffer =
      prices.offer === null ? "-" : prices.offer.toString();
    newInstrument.displayBid =
      prices.bid === null ? "-" : prices.bid.toString();
    return newInstrument;
  }

  private getIgTradingTicket(quickDealTicket: Ticket) {
    // Leaving ticketType here in order to add sharedealing as part of Upvest project
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const ticketType = resolveInstrumentTypeByInstrument(
      quickDealTicket.instrument,
    );
    return TurboOrderTicket;
  }

  generateDealReference = (): string => {
    return this.dealReferenceGenerator.generateDealReference();
  };

  destroy = () => {
    this.confirmationStreamingClient.destroy();
  };
}
