import { type ReactNode, useContext } from "react";
import { resolveInstrumentTypeByString } from "../utils/resolve-instrument-type";
import { useInstrumentData } from "./use-instrument-data";
import {
  SubscriptionTurboContext,
  SubscriptionTurboProvider,
} from "./use-turbo-subscription";

interface SubscriptionProviderProps {
  children: ReactNode;
  instrumentEpic: string;
}

export const SubscriptionProvider = ({
  children,
  instrumentEpic,
}: SubscriptionProviderProps) => {
  const { instrumentData } = useInstrumentData();

  // Leaving ticketType here in order to add sharedealing as part of Upvest project
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const instrumentType = resolveInstrumentTypeByString(
    instrumentData.instrumentType,
  );

  return (
    <SubscriptionTurboProvider
      instrumentEpic={instrumentEpic}
      snapshotBid={instrumentData.displayBid}
      snapshotOffer={instrumentData.displayOffer}
      snapshotMarketStatus={instrumentData.ncrMarketStatus}
    >
      {children}
    </SubscriptionTurboProvider>
  );
};

export const useSubscription = () => {
  const { instrumentData } = useInstrumentData();

  // Leaving resolvedInstrumentType here in order to add sharedealing as part of Upvest project
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const resolvedInstrumentType = resolveInstrumentTypeByString(
    instrumentData.instrumentType,
  );

  const subscriptionContext = useContext(SubscriptionTurboContext);

  if (subscriptionContext === null) {
    throw new Error("No subscriptions have been made");
  }
  return subscriptionContext;
};
