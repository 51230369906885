import React from "react";
import { type Instrument } from "../../types/instrument";
import { useTranslation } from "react-i18next";
import { resolveInstrumentTypeByInstrument } from "../../utils/resolve-instrument-type";
import InstrumentOptionType from "./InstrumentOptionType";
import { useETPTheme } from "../../hooks/use-etp-theme";
import { InstrumentTitleStyles } from "./InstrumentTitleStyles";

export interface InstrumentTitleProps {
  instrument: Instrument;
}

const InstrumentTitle = ({ instrument }: InstrumentTitleProps) => {
  const ETPtheme = useETPTheme();
  const { t } = useTranslation();
  const instrumentType = resolveInstrumentTypeByInstrument(instrument);

  const instrumentInfo = () => {
    return (
      <strong data-testid="instrument-strike-level">
        {instrument.strikeLevel} {t("ko-level")}
      </strong>
    );
  };

  return (
    <section>
      <InstrumentTitleStyles
        data-testid="instrument-name-container"
        $ETPtheme={ETPtheme}
      >
        <h3 data-testid="instrument-name">{instrument.instrumentName}</h3>
        <div className="instrument-info-container">
          {instrumentInfo()}
          <InstrumentOptionType
            instrumentType={instrumentType}
            optionType={instrument.optionType}
          />
        </div>
      </InstrumentTitleStyles>
    </section>
  );
};

export default InstrumentTitle;
