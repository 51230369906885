import React, { type Dispatch, useEffect, useState } from "react";
import { type InstrumentType } from "../../types/instrument-type";
import { type Ticket } from "../../types/ticket";
import SizeInput from "../sizeInput/SizeInput";
import UnderlyingExposure from "../sizeInput/UnderlyingExposure";
import { useInstrumentData } from "../../hooks/use-instrument-data";
import { type TicketAction } from "../../hooks/reducers/ticket-reducer";
import StopLimitRow from "./StopLimitRow";
import { OrderType } from "../../types/ig-trading-order-ticket";
import { OrderTabContentStyles } from "./OrderTabContentStyles";
import { useETPTheme } from "../../hooks/use-etp-theme";
import { OrderTypeTab } from "../../types/order-type-tab";
import ToolTip, {
  PointerLocation,
  xAxis,
  yAxis,
} from "../generics/tooltip/Tooltip";
import PriceCalculatorToolTipContent from "./PriceCalculatorToolTipContent";
import { t } from "i18next";

interface OrderTabsContentProps {
  orderTypeTab: OrderTypeTab;
  resolvedInstrumentType: InstrumentType;
  ticket: Ticket;
  setTicketSize: (ticketSize: number | null) => void;
  dispatch: Dispatch<TicketAction>;
  availablePosition: number;
  isQuickDealDisplayed: boolean;
}

const OrderTabContent = ({
  orderTypeTab,
  resolvedInstrumentType,
  ticket,
  setTicketSize,
  dispatch,
  availablePosition,
  isQuickDealDisplayed,
}: OrderTabsContentProps) => {
  const { instrumentDataUnderlying } = useInstrumentData();
  const etpTheme = useETPTheme();
  const [underlyingInputValue, setUnderlyingInputValue] = useState<
    number | null
  >(null);

  useEffect(() => {
    document.getElementById("size-row__input")?.focus();
  }, [orderTypeTab]);

  useEffect(() => {
    setUnderlyingInputValue(null);
  }, [orderTypeTab, ticket.orderType]);

  return (
    <OrderTabContentStyles
      className={`tabpanel tabpanel-${orderTypeTab}`}
      role="tabpanel"
      aria-labelledby={`tab-${orderTypeTab}`}
      $ETPtheme={etpTheme}
    >
      <SizeInput
        instrumentType={resolvedInstrumentType}
        ticketDirection={ticket.direction}
        ticketSize={ticket.size}
        setTicketSize={setTicketSize}
        availablePosition={availablePosition}
        ticketValidationErrors={ticket.validationErrors}
        dispatch={dispatch}
        isQuickDealDisplayed={isQuickDealDisplayed}
      />
      <UnderlyingExposure
        ticketSize={ticket.size ?? 0}
        scalingFactor={instrumentDataUnderlying.scalingFactor}
        multiplier={ticket.instrument.multiplier}
        isShareUnderlying={instrumentDataUnderlying.share}
        ticketDefaultCurrency={ticket.instrument.ticketDefaultCurrency.name}
      />
      {orderTypeTab === OrderTypeTab.STOPS_AND_LIMITS && (
        <>
          <div className="etp-quick-deal_calculator-heading">
            <span></span>
            <ToolTip
              testId="price-calculator-tooltip"
              className="price-calculator-tooltip"
              content={<PriceCalculatorToolTipContent />}
              attachment={{ y: yAxis.BOTTOM, x: xAxis.RIGHT }}
              targetAttachment={{ y: yAxis.TOP, x: xAxis.RIGHT }}
              offset={{ y: "10px", x: "-9px" }}
              pointerLocation={PointerLocation.BOTTOM}
              pointerPosition={77}
              tooltipBoxSize={{ width: "230px" }}
            >
              <p>{t("price-calculator.name")}</p>
            </ToolTip>
          </div>
          <div className="stop-limit-rows">
            <StopLimitRow
              inputType={"limit"}
              dispatch={dispatch}
              ticket={ticket}
              isInactive={ticket.orderType === OrderType.STOP_MARKET}
              isQuickDealDisplayed={isQuickDealDisplayed}
              setUnderlyingInputValue={setUnderlyingInputValue}
              underlyingInputValue={underlyingInputValue}
              underlyingScalingFactor={instrumentDataUnderlying.scalingFactor}
              underlyingDecimalPlacesFactor={
                instrumentDataUnderlying.decimalPlacesFactor
              }
            />
            <StopLimitRow
              inputType={"stop"}
              dispatch={dispatch}
              ticket={ticket}
              isInactive={ticket.orderType === OrderType.LIMIT}
              isQuickDealDisplayed={isQuickDealDisplayed}
              setUnderlyingInputValue={setUnderlyingInputValue}
              underlyingInputValue={underlyingInputValue}
              underlyingScalingFactor={instrumentDataUnderlying.scalingFactor}
              underlyingDecimalPlacesFactor={
                instrumentDataUnderlying.decimalPlacesFactor
              }
            />
          </div>
        </>
      )}
    </OrderTabContentStyles>
  );
};

export default OrderTabContent;
